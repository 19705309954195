<template>
  <div class="category">
    <div class="cover-container">
      <div class="cover py-5 bg-brand">
        <a href="" class="back card-secondary-lable" @click.prevent="goBack"><i class="la la-arrow-right la-2x"></i></a>
        <img :src="category.icon_url" alt="" class="" v-if="category.icon_url" />
      </div>
    </div>

    <div class="px-2">
      <div class="text-sm font-weight-bold h5 mb-4 mt-0 text-center">
        <span class="font-weight-bold h5 my-4 px-3 mt-0">{{
          category.name_ar
        }}</span>
      </div>
      <div class="row mx-0">

        <HorizontalProgramCardLoader v-if="$apollo.queries.category.loading" />

        <div
          class="col-md-12"
          v-for="program in category.programs"
          :key="program.id"
        >
          <ProgramHorizontalCard :program="program" />
        </div>

        <div class="col-md-12 text-center" v-if="!$apollo.queries.category.loading && !category.programs.length">
          <div class="main-card p-5">
            لا توجد برامج هنا!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramHorizontalCard from "../../components/ProgramHorizontalCard.vue";
import gql from "graphql-tag";
import HorizontalProgramCardLoader from '../../components/Loader/HorizontalProgramCardLoader.vue';

export default {
  components: {
    ProgramHorizontalCard,
    HorizontalProgramCardLoader,
  },

  data: () => {
    return {
      category: {},
    };
  },

  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    goBack() {
      if (this.hasHistory()) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },

  apollo: {
    category: {
      query: gql`
        query Property($id: ID) {
          category (id: $id) {
            id
            name_ar
            name_en
            icon_url

            programs {
              id
              name_ar
              name_en
              thumbnail_url
              cover_url

              tracksCount
            }
          }
        }`,
      variables () {   
        return {
            id: this.$route.params.id 
        }
      }
    },
  },
};
</script>
